// Link all thirdparty libs

//= jquery.min.js
//= popper.min.js
//= bootstrap.min.js
//= owl.carousel.min.js
//= photoswipe.min.js
//= photoswipe-ui-default.min.js

console.log("Imported thirdparty");
